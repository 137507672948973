import ReactGA from 'react-ga';

export const initGA = () => {
  ReactGA.initialize(process.env.REACT_APP_GA);
};

export const trackingPageGA = (page) => {
  ReactGA.pageview(page);
};

// export const eventGA = (categoryName, eventName) => {
//   ReactGA.event({
//     category: categoryName, // Required
//     action: eventName, // Required
//     label: 'labelName',
//     value: 10,
//     nonInteraction: false,
//   });
// };
