// import { RiFlowChart } from 'react-icons/ri';
import { DiRubyRough, DiRor } from 'react-icons/di';

import { FcTreeStructure, FcProcess, FcWorkflow } from 'react-icons/fc';
import { BiNetworkChart } from 'react-icons/bi';

export const rubyGeneratorButtonItems = {
  menuTitle: 'Ruby/Rails generators',
  menuIcon: <DiRubyRough className='mr-2 text-2xl' />,
  menuItems: [
    {
      name: 'Models and associations',
      description: 'A user has many posts...I think...',
      icon: <BiNetworkChart className='text-pink-500' />,
      href: '/ruby-on-rails/models-and-associations',
    },
    {
      name: 'Create some simple code',
      description:
        "Use the AI to help write a method or class etc by explaning what you'd like",
      icon: <FcTreeStructure />,
      href: '/ruby-on-rails/create-some-code',
    },
    {
      name: 'Refactor',
      description: 'Refactor your code',
      icon: <FcProcess />,
      href: '/ruby-on-rails/refactor',
    },
    {
      name: 'Explain code',
      description:
        'A line by line explantion of what your code is doing. Hopefully...',
      icon: <FcWorkflow />,
      href: '/ruby-on-rails/explain',
    },
  ],
  footer: {
    title: 'Simple AI generated helpers for Ruby on Rails',
    description:
      "Because these bits and bobs are AI generated, there's no guarantee that any of it will make sense ",
    icon: <DiRor className='text-red-500 text-5xl' />,
  },
};
