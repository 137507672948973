export const generalStopBody = (input) => {
  return {
    max_tokens: 500,
    top_p: 1,
    stop: ['"""'],
    best_of: 1,
    temperature: 0,
    frequency_penalty: 0,
    prompt: input,
  };
};

export const refactorStopBody = (input) => {
  return {
    max_tokens: 500,
    top_p: 1,
    stop: '=end',
    best_of: 1,
    temperature: 0,
    frequency_penalty: 0,
    prompt: input,
  };
};

export const explainStopBody = (input) => {
  return {
    max_tokens: 500,
    top_p: 1,
    stop: ['"""'],
    best_of: 1,
    temperature: 0,
    frequency_penalty: 1,
    presence_penalty: 2,
    prompt: input,
  };
};
