import { useSelector } from 'react-redux';
import { BiRotateRight } from 'react-icons/bi';
import { DiRubyRough, DiRor } from 'react-icons/di';

import Button from '../buttons/Button';
import { rubyButtonGroup } from '../../common/ButtonGroups';

function ButtonGroup({
  examplePrompted,
  body,
  submitButtonText = 'Generate!',
  handleGenerateClick,
  handleResetClick,
  handleExampleClick,
}) {
  const loading = useSelector((state) => state.input.loading);

  return (
    <>
      <div className='mb-1 lg:mb-0'>{rubyButtonGroup()}</div>
      <div className='grid grid-cols-1 md:grid-cols-3 gap-4 justify-center'>
        <div></div>
        <div className='flex justify-center'>
          {loading ? (
            <Button title='Generating!' disabled>
              <BiRotateRight className='animate-spin text-2xl' />
            </Button>
          ) : (
            <Button
              title={
                examplePrompted && !body
                  ? 'Now lets generate!'
                  : submitButtonText
              }
              onClick={handleGenerateClick}
            />
          )}
          {!examplePrompted && (
            <Button
              title='Show example'
              onClick={handleExampleClick}
              color='bg-purple-600'
            />
          )}
          {body && (
            <Button
              title='Reset'
              color='bg-purple-600'
              onClick={handleResetClick}
            />
          )}
        </div>
        <div className='flex justify-self-center md:justify-self-end'>
          <DiRubyRough className='text-white text-5xl' />
          <DiRor className='text-white text-5xl' />
        </div>
      </div>
    </>
  );
}

export default ButtonGroup;
