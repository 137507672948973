import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../features/user/userSlice';
import inputReducer from '../features/input/inputSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    input: inputReducer,
  },
});
