import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';

import { refactoring } from '../../config/railsInputFormats';
import { refactorStopBody } from '../../config/promptBodies';
import { refactorPrompt } from '../utils/promptBodyCreator';
import {
  setInput,
  resetInput,
  resetState,
} from '../../features/input/inputSlice';
import { initGA, trackingPageGA } from '../../config/ga';

import TextArea from '../components/inputs/TextArea';
import Input from '../components/inputs/Input';
import CodeHighlighter from '../components/codeHighlighter/CodeHighlighter';
import ButtonGroup from '../components/buttons/ButtonGroup';

function Refactor() {
  const { placeHolder, prompt, example } = refactoring;
  const { response, loading } = useSelector((state) => state.input);

  const dispatch = useDispatch();

  const [localState, setLocalState] = useState({
    body: '',
    input: '',
    examplePrompted: false,
    errors: undefined,
  });
  const { body, examplePrompted, input } = localState;

  useEffect(() => {
    dispatch(resetState());
    initGA();
    trackingPageGA(window.location.pathname);
  }, []);

  const handleChange = (e) => {
    setLocalState({ ...localState, [e.target.name]: e.target.value });
  };

  const handleGenerateClick = () => {
    const inputData = {
      prompt: refactorPrompt(prompt, body, input),
      promptObject: refactorStopBody,
    };

    if (body) {
      body && dispatch(setInput(inputData));
    } else {
      toast.error('Input is empty!');
    }
  };
  const handleExampleClick = () => {
    const { body, input } = example;
    setLocalState({
      ...localState,
      body,
      input,
      examplePrompted: true,
    });
  };

  const handleResetClick = () => {
    setLocalState({
      body: '',
      input: '',
      examplePrompted: false,
      error: undefined,
    });
    dispatch(resetInput());
  };
  return (
    <>
      <h1 className='break-normal text-2xl md:text-5xl uppercase bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-blue-500'>
        Refactor
      </h1>
      <div className='gradient p-5 rounded-lg'>
        <TextArea
          handleChange={handleChange}
          placeholder={placeHolder}
          prompt={prompt}
          loading={loading}
          body={body}
        />
        <Input
          placeholder={'Turn the above code into a link_to'}
          input={input}
          handleChange={handleChange}
        />
        <ButtonGroup
          loading={loading}
          examplePrompted={examplePrompted}
          body={body}
          submitButtonText='Refactor!'
          handleGenerateClick={handleGenerateClick}
          handleResetClick={handleResetClick}
          handleExampleClick={handleExampleClick}
        />
        {response && <CodeHighlighter code={response} language='ruby' />}
      </div>
    </>
  );
}

export default Refactor;

// ('write me models and associations in ruby on rails based on the below:\n\na user can create posts\nposts have comments\nposts and comments can be liked\n\n"""');
