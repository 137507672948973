import TextareaAutosize from 'react-textarea-autosize';

function TextArea({ placeholder, handleChange, body }) {
  return (
    <div>
      <div className='mb-3 pt-0'>
        <TextareaAutosize
          name='body'
          type='text'
          minRows={4}
          value={body}
          placeholder={placeholder}
          className='w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none'
          onChange={handleChange}
        />
      </div>
    </div>
  );
}

export default TextArea;
