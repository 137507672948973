import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';

function CodeHighlighter({ code, language }) {
  return (
    <SyntaxHighlighter language={language} style={okaidia} wrapLongLines>
      {code}
    </SyntaxHighlighter>
  );
}

export default CodeHighlighter;
