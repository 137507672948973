import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import { Toaster } from 'react-hot-toast';
import { MdInfoOutline } from 'react-icons/md';

// import Navbar from './app/components/navbar/Navbar';
// import Home from './app/containers/Home';
import ModelsAssociations from './app/containers/ModelsAssociations';
import CreateMethod from './app/containers/CreateCode';
import Refactor from './app/containers/Refactor';
import Explain from './app/containers/Explain';

function App() {
  return (
    <div className='app'>
      <Toaster
        toastOptions={{
          duration: 3000,
          success: {
            style: {
              background: 'green',
            },
          },
          error: {
            icon: <MdInfoOutline />,
            style: {
              background: '#f59e0b',
              color: 'white',
            },
          },
        }}
      />
      <Router>
        {/* <Navbar /> */}
        <div className='container mx-auto px-4 mt-20'>
          <Switch>
            <Route exact path='/' component={CreateMethod} />
            <Route
              exact
              path='/ruby-on-rails/models-and-associations'
              component={ModelsAssociations}
            />
            <Route
              exact
              path='/ruby-on-rails/create-some-code'
              component={CreateMethod}
            />
            <Route exact path='/ruby-on-rails/refactor' component={Refactor} />
            <Route exact path='/ruby-on-rails/explain' component={Explain} />
          </Switch>

          <small className='flex justify-center uppercase pt-2 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-blue-500 md:justify-end text-center'>
            All responses are generated by OpenAI's GPT-3 and as such can be
            wildly incorrect
          </small>
          <p className='mt-4 mb-5 flex justify-center uppercase bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-blue-500 md:justify-end md:mt-0'>
            over-engineered by{' '}
            <a
              className='text-pink-500'
              target='_blank'
              rel='noreferrer'
              href='https://github.com/jaarnie'
            >
              jaarnie
            </a>
          </p>
          <p className='mt-4 mb-5 flex justify-center uppercase bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-blue-500 md:justify-end md:mt-0'>
            Try out the
            <a
              className='text-pink-500 ml-1'
              target='_blank'
              rel='noreferrer'
              href='https://standupexcusegenerator.com/'
            >
              Standup Excuse Generator
            </a>
          </p>
        </div>
      </Router>
    </div>
  );
}

export default App;
