function Input({ placeholder, input, handleChange }) {
  return (
    <div>
      <div className='mb-3 pt-0'>
        <input
          name='input'
          type='text'
          value={input}
          placeholder={placeholder}
          className='px-3 py-4 placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-base border-0 shadow outline-none focus:outline-none focus:ring w-full'
          onChange={handleChange}
        />
      </div>
    </div>
  );
}

export default Input;
