export const modelsAndAssociationsPrompt = (prompt, body) => {
  return `"""${prompt + body}"""`;
};

export const refactorPrompt = (prompt, body, input) => {
  return body + input + prompt;
};

export const explainCodePrompt = (prompt, body) => {
  return body + prompt;
};

export const createMethodPrompt = (prompt, body) => {
  return `"""\n${body + prompt}`;
};
