import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from '../../config/index';

const initialState = {
  method: '',
  associations: '',
  loading: false,
  response: '',
  errors: '',
};

export const setInput = createAsyncThunk(
  'user/setInput',
  async (inputData, { rejectWithValue }) => {
    const { prompt, promptObject } = inputData;
    const body = promptObject(prompt.toLowerCase());
    try {
      const response = await api.post('/completions', body);
      return response.data.choices[0].text;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const inputSlice = createSlice({
  name: 'input',
  initialState,
  reducers: {
    resetInput: (state) => {
      state.response = '';
    },
    resetState: () => {
      return initialState;
    },
  },

  extraReducers: {
    [setInput.pending]: (state) => {
      state.loading = true;
    },
    [setInput.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.response = payload;
    },
    [setInput.rejected]: (state, { payload }) => {
      state.loading = false;
      state.errors = 'Oops, there seems to be an error!';
    },
  },
});

export const { methodInput, associationsInput, resetInput, resetState } =
  inputSlice.actions;

export default inputSlice.reducer;
