export const modelsAndAssociations = {
  placeHolder: 'A user can create a post...',
  prompt:
    'Write me models and associations in ruby on rails based on the below:\n\n',
  example:
    'A user can create a post\nA post can have comments\nComments can be liked',
};

export const refactoring = {
  placeHolder: 'Paste the code you want refactoring here...',
  prompt: 'in ruby on rails=end',
  example: {
    body: '<label class="radio | horizontal v-center" for="some_form">\n<%= radio_button_tag "quote_response", data: { action: "foo-controller#bar", "foo-controller-url": some_url_path(foo.bar, foo) } %>\n<%= t(".some_translation") %>\n</label>',
    input: `\n\nTurn the above into a link_to`,
  },
};

export const createCode = {
  placeHolder: "Describe what you'd like... eg. 'scaffold a user controller'",
  prompt: ' in ruby:',
  example: 'Write a method that checks if a user is subscribed to a thread',
};

export const explainCode = {
  placeHolder: 'Paste in some code...',
  prompt: `\nHere's what the above ruby code is doing:\n1.`,
  example:
    'after_commit on: :create do |payment|\n    if payment.chargeable_type == "Subscription" && Credits.feature_enabled?\n        Credits::SubscriptionChargedForm.create(payment.chargeable)\n    end\nend',
};
