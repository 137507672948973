import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { HiChevronDown } from 'react-icons/hi';
import { Link } from 'react-router-dom';

export default function PopoverButton({ items, color }) {
  const { menuTitle, menuIcon, menuItems, footer } = items;
  return (
    <div className='flex flex-wrap mb-5'>
      <Popover className='relative'>
        {({ open }) => (
          <>
            <Popover.Button
              className={`
                ${open ? '' : 'text-opacity-90'}
                ${color} text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 flex items-center`}
            >
              <span>{menuIcon}</span>
              <span>{menuTitle}</span>
              <HiChevronDown
                className={`${open ? '' : 'text-opacity-70'}
                  ml-2 h-5 w-5 text-orange-300 group-hover:text-opacity-80 transition ease-in-out duration-150`}
                aria-hidden='true'
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <Popover.Panel className=''>
                <div className='overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5'>
                  <div className='relative grid gap-8 bg-white p-7 lg:grid-cols-2'>
                    {menuItems.map((item) => (
                      <Link
                        key={item.name}
                        className='flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50'
                        to={item.href}
                      >
                        <div className='pr-3 text-3xl'>{item.icon}</div>

                        <div className='ml-4'>
                          <p className='text-sm font-medium text-gray-900'>
                            {item.name}
                          </p>
                          <p className='text-sm text-gray-500'>
                            {item.description}
                          </p>
                        </div>
                      </Link>
                    ))}
                  </div>
                  <div className='p-4 bg-gray-50'>
                    <a
                      href='##'
                      className='flow-root px-2 py-2 transition duration-150 ease-in-out rounded-md hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50'
                    >
                      <span className='flex items-center'>
                        {footer.icon}
                        <span className='text-sm font-medium text-gray-900'>
                          {footer.title}
                        </span>
                      </span>
                      <div className='block text-sm text-gray-500'>
                        {footer.description}
                      </div>
                    </a>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
